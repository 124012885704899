<template>
  <div class="container">
    <h1 class="main-claim">Notizblog</h1>
    <h2 class="sub-claim">Nachlesen und ansehen was bei uns so passiert</h2>

    <div class="news-list">
      <div class="news-item video">
        <p>Das neueste Video (in englischer Sprache):</p>
        <CookieControlledContent>
          <iframe data-cy="youtubeVideo" class="youtube-video"
            src="https://www.youtube.com/embed/?list=UULFXCyESAIxpoPhYu6VsnC3Wg" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </CookieControlledContent>
      </div>

      <div class="news-item post" v-for="(item, index) in feed" :key="index">
        <p class="description" v-html="item.description"></p>
        <img :src="item.image" />
        <p><a :href="item.link" target="_blank">Auf Mastodon mitreden ...</a></p>
        <p>Veröffentlicht: {{ formatDate(item.pubDate) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/library"

export default {
  name: "News",

  data: () => ({
    feed: []
  }),

  beforeMount() {
    this.loadRSS()
  },

  methods: {
    formatDate(date) {
      return formatDateTime(this.$i18n, date)
    },

    async loadRSS() {
      fetch('https://regenerate.social/@granjacaimitode.rss')
        .then(response => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(data => {
          const items = data.querySelectorAll("item");
          items.forEach(el => {
            const feedItem = {
              pubDate: el.querySelector("pubDate").textContent,
              link: el.querySelector("link").textContent,
              description: el.querySelector("description").textContent,
            }

            const media = el.querySelector("media\\:content, content")
            if (media) {
              feedItem.image = media.getAttribute("url")
            }

            this.feed.push(feedItem)
          })

        })
        .catch(error => console.error(error))

    }
  }
}
</script>

<style scoped>
.news-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.news-item {
  height: 100%;
  padding: 0.5em;
}

.youtube-video {
  width: 53vw;
  height: 30vw;
}

.post {
  width: 25vw;
}

.news-item IMG {
  width: 100%;
}

@media (max-width: 440px) {
  .news-list {
    flex-direction: column;
  }

  .post {
    width: 100%;
  }

  .youtube-video {
    width: 100%;
    height: unset;
  }
}
</style>
